<template>
  <div>
    <!-- 内容 -->
    <div class="essay">
      <div>
        <img :src="details.ImgUrl" style="width: 100%; height: 290px" />
        <div style="border-bottom: 10px solid #f8f8f8; padding: 20px 0">
          <div style="width: 95%; margin: auto">
            <div class="biaoti">
              <span class="title">{{ details.Name }}</span>
              <span class="money">{{ details.CateName }} </span>
            </div>
            <div style="color: #31d984; margin: 5px 0 10px;display: flex;align-items: center;justify-content: space-between;">
              <div><span>{{ details.Amount }}分</span> |
              <span>{{ details.MUnit }}</span></div>
              <van-stepper 
                  v-model="shopNum" 
                  theme="round"
                  min="0"
                  button-size="22"
                  disable-input
                  @change="Goodschange"
                  style="display: inline-block;" 
                  />
            </div>
            <div class="time" v-if="details.ShopTypeName != ''">
              <van-cell icon="shop-o" style="padding: 0" color="#c8cad8">
                <template #title>
                  <span class="custom-title time" style="font-size:16px;text"
                    >&nbsp;&nbsp;西南隅积分商城</span
                  >
                </template>
              </van-cell>
            </div>
          </div>
        </div>
        <div class="essay" style="width: 100%">
          <div style="margin-bottom: 20px">
            <div class="zhuyao">—&nbsp;&nbsp;商品详情&nbsp;&nbsp;—</div>
          </div>
          <div
            class="essayContent"
            style="text-indent: 0em"
            v-html="details.Describe"
          ></div>
        </div>
        <!-- <div class="button">
          <button @click="orderClick">下 单</button>
        </div> -->
      </div>
    </div>
    <!-- 弹出框 收货地址选择 -->
    <van-popup
      v-model="addressShow"
      round
      position="bottom"
      :style="{ height: '70%' }"
    >
      <div>
        <van-radio-group v-model="checked">
          <van-radio
            :name="item.BindId"
            v-for="item in addressList"
            :key="item.BindId"
            @click="radioClick(item.BindId)"
          >
            <div>
              <div class="radioBox">
                <span>收件人:{{ item.Name }}</span
                ><span>联系电话:{{ item.Mobile }}</span>
              </div>
              <div style="font-size: 14px; padding-top: 5px">
                地址:{{ item.Addr }}
              </div>
            </div>
          </van-radio>
        </van-radio-group>
        <div class="button">
          <button @click="PlaceOrder">下单</button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
  
  <script>
import { WxGetGoods } from "@/api/RealInfo";
import {
  WeGenerateOrder,
  WeGetMyBindInfoPage,
  WePlaceOrder,
  WeSaveOrderService,
  WeGetOrderGoodsList
} from "@/api/shop";
import config from "@/config";
import Vue from "vue";
import { Toast } from "vant";
import { Dialog } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
Vue.use(Toast);
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      details: {},
      list: [],
      goodsid: "", //商品id
      addressShow: false, //弹出框
      addressList: [], //地址
      checked: "", //选中内容
      usreData: [], //会员信息
      shopNum:"", //商品数量
      orderid:"",//订单id
      shoppingList:[], //购物车商品列表
    };
  },
  created() {
    this.wxGetPromotion(); //商品详情
  },
  methods: {
    // 数量改变
    Goodschange(row){
      WeSaveOrderService({
        //添加订单商品
        OpenID: getOpenId(),
        OrderId: this.orderid,
        GoodsId: this.goodsid,
        GoodsNum: row,
      }).then((res) => {
        console.log(res);
      });
    },
    // 选中收货地址
    radioClick(row) {
      console.log(row);
      this.checked = row;
    },
    //格式化日期
    filteryear(val) {
      if (val.StartDate != null && val.StartDate != "") {
        let gstime = val.StartDate.substring(0, 10);
        return gstime;
      }
    },
    // 获取商品详情
    wxGetPromotion() {
      var id = this.$route.params.Id.split("&");
      this.goodsid = id[0];
      this.orderid = id[1];
      WxGetGoods({ goodsId: this.goodsid }).then((res) => {
        this.details = res.data.data;
        this.getshoppingList(this.orderid);
      });
    },
    // 发起订单
    getShopOrder() {
      WePlaceOrder({
        //提交订单
        OpenID: getOpenId(),
        OrderId: orderid,
        BindId: checked,
        DeliveryMode: 1,
      }).then((res) => {

      });
    },
    // 选择商品
    orderClick() {
      this.addressShow = true;
      WeGetMyBindInfoPage({ openID: getOpenId() }).then((res) => {
        console.log(res);
        if (res.data.count == 0) {
          Dialog.confirm({
            title: "提醒",
            message: "您还没有添加收货地址,是否前往添加!",
          })
            .then(() => {
              if (window.__wxjs_environment === "miniprogram") {
                window.wx.miniProgram.navigateTo({
                  url: "../my/user/oldManList/index?name='收货地址'",
                });
              } else {
                alert("请在小程序中打开");
              }
            })
            .catch(() => {
              this.addressShow = false;
              return false;
            });
        } else {
          this.addressList = res.data.data;
        }
      });
    },
    // 获取购物车列表
    getshoppingList(row) {
      WeGetOrderGoodsList({ openID: getOpenId(), orderId: row }).then((res) => {
        this.shoppingList = res.data.data;
        for (let i = 0; i < this.shoppingList.length; i++) {
            if(this.shoppingList[i].GoodsId == this.goodsid){
              this.shopNum = this.shoppingList[i].GoodsNum
            }
        }
      });
    },
    // 下单
    PlaceOrder() {
      var checked = this.checked;
      var goodsid = this.goodsid;
      var that = this;
      WeGenerateOrder({ ShopId: 74, OpenID: getOpenId() }).then((res) => {
        //发起订单
        console.log(res);
        var orderid = res.data.data;
        if (res.data.code == 0) {
          WeSaveOrderService({
            //添加订单商品
            OpenID: getOpenId(),
            OrderId: orderid,
            GoodsId: goodsid,
            GoodsNum: 1,
          }).then((res) => {
            if (res.data.code == 0) {
              WePlaceOrder({
                //提交订单
                OpenID: getOpenId(),
                OrderId: orderid,
                BindId: checked,
                DeliveryMode: 1,
              }).then((res) => {
                if (res.data.code == 0) {
                  //订单成功
                  Toast.success("提交成功!");
                  that.addressShow = false;
                } else {
                  Dialog.alert({
                    //订单失败
                    title: "提示",
                    message: res.data.msg,
                  }).then(() => {
                    that.addressShow = false;
                  });
                }
              });
            }
          });
        }
      });
    },
  },
};
</script>
  
  <style scoped>
.custom-indicator {
  position: absolute;
  right: 15px;
  bottom: 15px;
  padding: 2px 5px;
  font-size: 14px;
  color: #ffffff;
  /* background: rgba(255, 255, 255, 0.788); */
}
.biaoti {
  display: flex;
  justify-content: space-between;
}
.title {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 550;
}
.money {
  line-height: 33px;
  font-family: PingFang SC;
  font-weight: 550;
  color: #fe5b47;
  font-size: 18px;
}
.time {
  /* display: flex;
    justify-content: space-between; */
  padding: 5px 0;
  color: #0c0c0c;
  font-weight: bold;
  line-height: 30px;
}
.zhuyao {
  font-size: 1.17em;
  font-weight: bold;
  margin-top: 1em;
  text-align: center;
}
.zhuyaoJd {
  font-size: 1.17em;
  font-weight: bold;
  margin-top: 1em;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.zhuyaoJd span {
  color: rgb(153, 153, 153);
  float: right;
  font-size: 12pt;
  font-weight: 300;
}
.waterfall {
  /* width: 95%; */
  margin: 10px auto;
  column-count: 2;
  column-gap: 0;
  column-width: auto;
}
.waterfall .list {
  margin: 0px 7px 10px 7px;
  break-inside: avoid;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 5px 17px 1px rgba(0, 0, 0, 0.07);
}
.list img {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  /* height: 160px; */
  /* border-radius: 0px; */
}
.address {
  margin: 10px 0;
  color: #92600d;
  font-size: 16px;
}
.button {
  width: 100%;
  text-align: center;
}
.button button {
  position: fixed;
  bottom: 15px;
  width: 80%;
  height: 40px;
  line-height: 40px;
  background: #01a0fb;
  color: #fff;
  border: none;
  margin: 8px auto 15px;
  border-radius: 6px;
  left: 10%;
}
.van-radio {
  background: #f5f5f5;
  width: 80%;
  margin: 10px auto;
  padding: 6px 15px;
  border-radius: 6px;
}
.radioBox {
  display: flex;
  justify-content: space-between;
  width: 70vw;
  font-size: 14px;
}
</style>